.footer__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: black;
  padding: 0 50px 60px;
}

.footer__links {
  list-style: none;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.footer__logo p {
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 25px;
  margin-bottom: 10px;
}

.footer__social {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.footer__section2 {
  background: #6947d0;
  color: #fff;
  width: 100%;
  cursor: pointer;
}

.footer__two {
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}

.footer__logo img {
  width: 53px;
  height: 53px;
  flex-shrink: 0;
  margin-left: 7px; /* Space between the icon and the text */

}

.footer__two p {
  text-align: center;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 23.796px;
  font-style: normal;
  font-weight: 500;
}
.email__link {
  font-size: 30px;
  text-decoration: none;
  font-family: "Schibsted Grotesk", sans-serif;
  font-weight: 400;
  color: white; /* Inherit text color */
}
.size-6{
  width: 26px; 
  height: 26px; 
  margin-left: 10px; 

}

@media (max-width: 1200px) {
  
  .footer__content {
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 20px;
    gap: 40px;
  }
  .footer__links {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 30px;
    margin-top: 30px;
  }
  /* .footer__two {
    flex-direction: column;

  } */
  .footer__two p {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
  }

  .footer__logo p {
    
    font-size: 18px;
    
  }
  .email__link {
    font-size: 25px;
  }
}
