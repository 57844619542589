:root {
  background-color: #ffffff;
}
@font-face {
  font-family: "Nexa light";
  src: url("/src/assets/Nexa/Nexa.ttf") format("truetype"); /* Fallback */
  font-weight: normal; /* Adjust if necessary */
  font-style: normal; /* Adjust if necessary */
}

@font-face {
  font-family: "Nexa Bold";
  src: url("/src/assets/Nexa/Nexa-Heavy.ttf") format("truetype"); /* Modern browsers */
  font-weight: bold;
  font-style: normal;
}

.header__section {
  background-image: url("https://res.cloudinary.com/dwrkgmmsz/image/upload/v1721995204/background_bmhpxw.png");
  background-position: center;
  background-size: cover;
  margin-top: 0;
  padding-bottom: 100px;
}

.text {
  font-family: "Nexa Regular", sans-serif;
  color: #000;
  font-size: 40px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  font-weight: normal;
}

.text--normal {
  font-weight: 900;
  font-size: 40px;
  font-family: "Nexa Bold", sans-serif;
}

.header__text {
  font-family: "Nexa Regular", sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-transform: uppercase;
}

.header__text__con p {
  text-align: center;
  font-size: 40px;
  font-family: "Nexa Regular", sans-serif;
  font-weight: 100;
  font-style: normal;
}
.header__text__con {
  text-align: center;
  padding: 100px 60px;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.btn {
  /* position: relative; */
  margin-top: 60px;
  text-align: center;
  color: #fff;
  font-style: normal;
  background: #6947d0;
  text-transform: uppercase;
  font-family: "Schibsted Grotesk";
  cursor: pointer;
  padding: 20px 120px;
  border: 2px solid rgba(105, 71, 208, 0.5);
  font-family: "Schibsted Grotesk", sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: rgba(105, 71, 208, 0.5) -10px 10px;
}

.btn p {
  font-size: 14px;
  font-weight: 700;
}
.btn:hover {
  box-shadow: rgba(105, 71, 208, 0.819) -10px 10px;
}

.hero_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.hero_image img {
  width: 250px;
}

.recent__section {
  /* text-align: center; */
  background: #050000;
  overflow: hidden; /* Hide the overflowing text */
  padding-bottom: 100px;
}

.recent__work {
  padding-top: 100px;
  display: flex;
  overflow: hidden; /* Hide the overflowing text */
  gap: 30px;
  white-space: nowrap;
  overflow-x: auto;
  animation: scrollText 10s infinite linear;
  width: 250%;
  transform: translateX(-50%); /* Center the content */
  overflow: hidden; /* Hide the overflowing text */
}
.recent__work h2 {
  display: inline-block;
  color: #fff;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
}

/* Define keyframes for the scrolling animation */
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.work__img {
  width: 55%;
}

.work__img img {
  width: 100%;
  box-shadow: rgba(240, 240, 240, 0.146) -13px 20px;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
}

.work__img img:hover {
  box-shadow: rgba(240, 240, 240, 0.443) -20px 20px;
}

.work__con {
  display: flex;
  padding-bottom: 100px;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: rgba(217, 217, 217, 0.8);
}

.last__b {
  border: none;
}

.work__section {
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 100px 80px;
}

.work__con h1 {
  color: white;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-top: 20px;
}
.who_under_text {
  gap: 20px;
}
.under_text {
  display: flex;
  gap: 10px;
}
.under_text p {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(217, 217, 217, 0.19);
  color: white;
  padding: 6px 20px;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 24px;
  margin-top: 23px;
}

.btn2 {
  color: #fff;
  font-style: normal;
  background: #151515;
  text-transform: uppercase;
  cursor: pointer;
  padding: 20px 140px;
  width: 1000px;
  border: 1px solid #fff;
  font-family: "Schibsted Grotesk";
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: rgba(196, 196, 196, 0.412) -5px 7px;
  text-align: center;
  font-family: "Schibsted Grotesk", sans-serif;
}
.btn2 p {
  font-size: 19.519px;
  font-family: "Schibsted Grotesk";
}
.btn2:hover {
  box-shadow: rgba(240, 240, 240, 0.831) -20px 10px;
}

.btn-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*********************************** OFFER SECTION *****************************************************/

.offer__section {
  padding: 100px 80px;
}

.offer__header {
  display: flex;
  gap: 300px;
  margin-top: 100px;
  padding-bottom: 80px;
  border-bottom: 2px solid #d9d9d9;
}

.last__border {
  border-bottom: none;
}

.offer{
  display: flex;
  align-items: center;
}

.offer__title {
  width: 30%;
  font-size: 2.6rem;
  font-family: "Schibsted Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;
}
.offer__title h1{
  font-family: "Schibsted Grotesk", sans-serif;
  font-weight: 400;

}


.p {
  color: rgba(0, 0, 0, 0.55);
  font-style: normal;
  font-weight: 700;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.n__one {
  display: flex;
  align-items: flex-start;
}
.p__one {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Schibsted Grotesk", sans-serif;
  line-height: 150%; /* 54px */
}

.P__two {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
  font-family: "Schibsted Grotesk", sans-serif;
  line-height: 170%; /* 54px */
}

.offer__con {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 40px;
}

h2 {
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-style: normal;

}

h3 {
  font-size: 23px;
  font-family: "Schibsted Grotesk", sans-serif;
  color: rgba(0, 0, 0, 0.55);
  font-weight: 200;
  width: 300%;
}

.number {
  display: inline-flex;
  justify-content: center;
  border-radius: calc(0.8 * 10vw); /* Responsive border radius */
  align-items: center;
  width: 200px;
  height: 60px;
  border: 2px solid rgba(0, 0, 0, 0.55);
  font-size: 18px;
  margin-right: 26px;
  font-family: "Schibsted Grotesk", sans-serif;
  font-weight: 400;
}

.who_link p {
  color: var(--Gulf-Blue-950, #001a52);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 0;
  margin-top: 10px;
  font-family: "Schibsted Grotesk", sans-serif;
}

.what__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  border-bottom: 2px solid #d9d9d9;
}
.what-items{
  width: 100%;
  max-width: 600px;
}



.questions {
  display: flex;
  width: 100%;
  max-width: 700px;
  padding: 30px 0;
  justify-content: space-between;
  text-align: left;
  font-size: 25px;
  gap: 60px;
  font-family: "Schibsted Grotesk", sans-serif;
  font-style: normal;
  font-weight: 559;
  line-height: 140%;
}

.question svg {
  color: #606060;
}

.answers {
  text-align: left;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 25px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 25px;
  font-style: normal;
  width: 100%;
  max-width: 700px;
  font-weight: 400;
  padding: 30px 0;
  border-bottom: 2px solid #d9d9d9;
}

/************************* Styling for individual FAQ items ************************************/

.summary__section {
  padding: 100px 60px;
  background: #6947d0;
}

.summary__text {
  padding: 40px 0;
}

.summary__text p {
  color: #fff;
  font-family: "Schibsted Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
  font-size: 38px;
}

.recent__work h2 {
  display: inline-block;
  color: #fff;
  font-family: "Schibsted Grotesk", sans-serif;
  font-size: 100.391px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -3.342px;
}

.getintouch {
  padding: 60px 60px;
  overflow: hidden; /* Hide the overflowing text */
  position: relative; /* For absolute positioning of the scrolling content */
}

.get-in-touch-item img {
  width: 60px;
}

.get-in-touch-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  animation: scrollText 5s infinite linear;
  transform: translateX(-50%); /* Center the content */
  white-space: nowrap;
}

.get-in-touch-item span {
  font-size: 50px;
  font-family: "Schibsted Grotesk", sans-serif;
  color: #333;
  font-weight: 400;
}

.getintouch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border: 2.777px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 30px;
  overflow-x: auto;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Optional: Hide scrollbar while allowing scrolling */
.getintouch-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@media (max-width: 999px) {
  .header__text {
    font-size: 60px;
    line-height: 60px;
  }
  .questions{
    max-width: 100%;

  }

  .text--normal {
    font-size: 25px;
  }
  .text {
    font-size: 25px;
  }

  .header__text__con p {
    font-size: 25px;
  }

  .header__text__con {
    padding: 100px 40px;
  }
  .hero_image img {
    width: 200px;
  }

  .recent__work h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }

  .work__section {
    padding: 50px 20px;
  }

 
  .work__con {
    padding-bottom: 50px;
    gap: 50px;

  }
  .offer__section {
    padding: 50px 20px;
  }

  .offer__header {
    flex-direction: column;
    margin-top: 50px;
    gap: 40px;
  }

  .what__header {
    flex-direction: column;
    gap: 30px;
  }
  .offer__title {
    /* font-size: 30px; */
    width: 100%;
  }
  .p__one {
    font-size: 20px;
  }

  .p {
    font-size: 20px;
  }

  .P__two {
    font-size: 20px;
  }

  .offer__con {
    width: 90%;
  }

  /* .what-item{
    padding: 10px;
  } */
  .questions {
    font-size: 18px;
    gap: 160px;
  }
  .summary__section {
    padding: 50px 20px;
  }
  .summary__text {
    padding: 30px 0;
  }
  .summary__text p {
    font-size: 28px;
    line-height: 124%;
    /* height: 600px; */
  }
  .get-in-touch-item span {
    font-size: 28px;
  }
  .get-in-touch-item img {
    width: 30px;
  }
  .work__img {
    width: 100%;
  }

  .work__img img {
    width: 100%;
  }

  .btn {
    margin-top: 30px;
    text-align: center;
    color: #fff;
    font-style: normal;
    background: #6947d0;
    padding: 20px 80px;
  }

  .btn p {
    font-size: 14px;
    font-weight: 700;
  }

  .getintouch-container {
    gap: 30px;
  }
  .what-items{
    max-width: 100%;
  }

  
  h2{
    font-size: 20px;
  }
  .faq-section {
    padding: 50px 30px;
  }
  .btn2 {
    width: 700px;
  }
  .number {
    width: 100px;
    height: 40px;
  }

  .offer__title h1{
    font-size: 30px;
  }
  .work__con h1{
    font-size: 30px;
  }
  .under_text p{
    font-size: 18px;
  }
}




@media (max-width: 780px) {
  .header__text {
    font-size: 60px;
    line-height: 60px;
  }

  .text--normal {
    font-size: 20px;
  }
  .text {
    font-size: 20px;
  }

  .header__text__con p {
    font-size: 20px;
    margin: 12px 30px;
  }

  .header__text__con {
    padding: 100px 40px;
  }
  .hero_image img {
    width: 200px;
  }

  .recent__work h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }

  .work__section {
    padding: 50px 20px;
  }

 
  .work__con h1 {
  
    font-size: 30px;
   
  }
  .under_text p{
    font-size: 14px;

  }
  .offer__section {
    padding: 50px 20px;
  }

  .offer__header {
    flex-direction: column;
    margin-top: 50px;
    gap: 40px;
  }

  .what__header {
    flex-direction: column;
    gap: 30px;
  }
  .offer__title {
    /* font-size: 30px; */
    width: 100%;
  }
  .p__one {
    font-size: 16px;
  }

  .p {
    font-size: 18px;
  }

  .P__two {
    font-size: 16px;
  }

  .questions {
    font-size: 18px;
    padding: 10px;
    gap: 30px;
  }
  .answers {
    font-size: 16px;
    padding: 30px 10px;
  }
  .summary__section {
    padding: 40px 20px;
  }
  .summary__text {
    padding: 30px 0;
  }
  .summary__text p {
    font-size: 20px;
    line-height: 124.061%; /* 19.85px */

  }
  .get-in-touch-item span {
    font-size: 28px;
  }
  .get-in-touch-item img {
    width: 50px;
  }
  .work__img {
    width: 100%;
  }

  .work__img img {
    width: 100%;
  }

  .btn {
    margin-top: 60px;
    padding: 6px 65px;
  }

  .btn p {
    font-size: 14px;
    font-weight: 700;
  }

  .getintouch {
    padding: 60px 10px;
  }
  .getintouch-container {
    gap: 20px;
  }

  .work__con {
    padding-bottom: 50px;
    flex-direction: column;
  }

  .btn2 {
    padding: 20px 0;
    width: 300px;
  }

  .btn2 p{
    font-size: 13px;
    padding: 5px 0;
    width: 300px;
  }


  .offer__title {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }

  .number {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
  h2{
    font-size: 20px;
  }
}
